import { Textarea, ActionIcon } from '@mantine/core'
import { useForm } from '@mantine/form'
import { zodResolver } from 'mantine-form-zod-resolver'
import { z } from 'zod'

import Button from 'src/components/Buttons/Button/Button'
import { ActivityLogType } from 'src/graphql/types/activityLogs'
// import IconChevronDown from 'src/icons/IconChevronDown'
import IconPlane from 'src/icons/IconPlane'

const MAX_NOTE_LENGTH = 250

const NoteInput = ({ onCreate }) => {
  const noteInputSchema = z.object({
    content: z.string().min(1).max(MAX_NOTE_LENGTH),
  })

  const form = useForm({
    initialValues: {
      content: '',
    },

    validate: zodResolver(noteInputSchema),
  })

  const onFormSubmit = (values) => {
    onCreate({
      type: ActivityLogType.NOTE,
      ...values,
    })
    form.reset()
  }

  return (
    <form
      onSubmit={form.onSubmit((values) => onFormSubmit(values))}
      className="flex flex-row items-center gap-2"
    >
      <Textarea
        placeholder="Write a note here..."
        autosize={true}
        maxRows={3}
        className="flex-1"
        maxLength={MAX_NOTE_LENGTH}
        withAsterisk
        // TODO: Enable it once we have Note templates ready to go
        // rightSection={
        //   <div className="flex flex-row items-center bg-white">
        //     <ActionIcon variant="subtle" size={20}>
        //       <IconChevronDown className="fill-none stroke-doubleNickel-brand-500" />
        //     </ActionIcon>
        //   </div>
        // }
        {...form.getInputProps('content')}
      />
      <Button text="Save" disabled={!form.isValid()} type="submit" />
    </form>
  )
}

export default NoteInput
